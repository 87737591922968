<template>
	<div>
		<navComponents />
		<div class="bg1" :style="{ width: screenWidth }">
			<div class="w">
				<div class="p1">连接、效率、可靠、敏捷</div>
				<div class="p2">通过构建实时且持续的端到端数据链路，快速实现多源异构数据融合，让行业用户专注数据价值释放，全面拥抱数智化转型。</div>
				<button v-if="!isMobile" @click="jump2detail">了解详情</button>
			</div>
		</div>
		<div class="bg2" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg2-title">
					<div class="p1">客户痛点</div>
					<div class="hengxian"></div>
				</div>
				<div class="bg2-4box">
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-1"></i>
						</div>
						<div class="p1">业务响应慢</div>
						<div class="p2">业务实时用数需求高，烟囱式IT架构限制，导致响应速度慢</div>
					</div>
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-kehutongdian2"></i>
						</div>
						<div class="p1">性能差</div>
						<div class="p2">支持业务端实时去数需求，数据传输性能差</div>
					</div>
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-3"></i>
						</div>
						<div class="p1">稳定性低</div>
						<div class="p2">支持业务端实时去数需求，数据传输稳定性低</div>
					</div>
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-4"></i>
						</div>
						<div class="p1">平台管理性差</div>
						<div class="p2">实时数据的链路基于业务驱动的形式建设而成，对整体的数据管理提出了非常高的要求</div>
					</div>
				</div>
				<div class="bg2-4box">
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-5"></i>
						</div>
						<div class="p1">数据质量难以控制</div>
						<div class="p2">接口类型、技术规范类型多，不同时效等级，数据质量难以控制</div>
					</div>
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-6"></i>
						</div>
						<div class="p1">质量低，流通慢</div>
						<div class="p2">同一份数据应用场景多，难以实现数据的高质量以及高效流通</div>
					</div>
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-7"></i>
						</div>
						<div class="p1">多源数据难以整合</div>
						<div class="p2">内外部数据整合成本高，难于有效共享使用</div>
					</div>
					<div class="smallBox">
						<div class="bg2-4box-small-icon">
							<i class="iconfont icon-solution-bg2-8"></i>
						</div>
						<div class="p1">共享交换效率低</div>
						<div class="p2">集成不同的云产品并在云生态系统中实现数据的自动化迁移难度大</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg3" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg3-title">
					<div class="p1">「解决方案」</div>
					<div class="hengxian"></div>
				</div>
				<div class="bg3-8box">
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-1"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">提升效率</div>
							<div class="p2">提升业务数据需求响应效率</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-2"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">连续性保障</div>
							<div class="p2">保障实时数据业务连续性</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-3"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">统一管理</div>
							<div class="p2">实现实时数据链路统一管理</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-4"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">降低成本</div>
							<div class="p2">降低实时数据使用成本</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-5"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">易管理</div>
							<div class="p2">适配企业外部数据的统一采购、统一登记等管理流程</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-6"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">场景丰富</div>
							<div class="p2">满足各类业务场景数据需求</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-7"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">数据互联互通</div>
							<div class="p2">帮助客户实现其外部数据的统一接入、统一服务</div>
						</div>
					</div>
					<div class="bg3-smallbox">
						<div class="bg3-smallbox-icon">
							<i class="iconfont icon-solution-bg3-8"></i>
						</div>
						<div class="bg3-smallbox-text">
							<div class="p1">高效部署</div>
							<div class="p2">迅速建立数据服务管道，实现简单、快速、安全的多云集成</div>
						</div>
					</div>
				</div>
				<div class="bg3-title">
					<div class="p1">「方案价值」</div>
					<div class="hengxian"></div>
				</div>
				<div class="bg3-2box">
					<div class="bg3-2box-left"></div>
					<div class="bg3-2box-right">
						<div class="bg3-2box-right-4smallBox">
							<div class="bg3-2box-right-4smallBox-icon">
								<div class="icon1">
									<i class="iconfont icon-solution-bg2-2"></i>
								</div>
								<div class="p1">秒级延时</div>
								<div class="jiaicon">+</div>
								<div class="icon2">
									<i style="" class="iconfont icon-solution-bg3-22"></i>
								</div>
								<div class="p2">多并发</div>
							</div>
							<div class="p3">低延迟高性能，可以很好地满足客户对数据实时同步、更新、使用的需求</div>
						</div>
						<div class="bg3-2box-right-4smallBox">
							<div class="bg3-2box-right-4smallBox-icon">
								<div class="icon1">
									<i class="iconfont icon-solution-bg3-33"></i>
								</div>
								<div class="p1">分布式</div>
								<div class="jiaicon">+</div>
								<div class="icon2">
									<i class="iconfont icon-solution-bg3-44"></i>
								</div>
								<div class="p2">一致性保证</div>
							</div>
							<div class="p3">高可用分布式架构以及数据一致性保证，可以确保业务稳定、准确运行</div>
						</div>
						<div class="bg3-2box-right-4smallBox">
							<div class="bg3-2box-right-4smallBox-icon">
								<div class="icon1">
									<i class="iconfont icon-solution-bg3-55"></i>
								</div>
								<div class="p1">配置简单</div>
								<div class="jiaicon">+</div>
								<div class="icon2">
									<i class="iconfont icon-solution-bg3-66"></i>
								</div>
								<div class="p2">可追踪</div>
							</div>
							<div class="p3">可视化配置及管理，快速搭建多源异构数据管道，详尽的提示信息，为复盘和排查问 题提供有效保障</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<CopyrightComponents />
	</div>
</template>

<script>
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
export default {
	components: { navComponents, CopyrightComponents },
	props: {},
	data() {
		return {
			screenWidth: "",
			screenHeight: "",
			mobileSrc: `url(${require("@/assets/images/solution-bg1-mobile.png")})`,
			pcSrc: `url(${require("@/assets/images/solution-bg1.png")})`,
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {
		screenWidth() {
			console.log(this.screenWidth); //浏览器窗口变化时，打印宽度。
		},
	},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {
		jump2detail() {
			window.scrollTo({
				top: 1170,
				behavior: "smooth",
			});
		},
	},
};
</script>

<style scoped lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.bg1 {
	height: 550px;
	background-image: url("~@/assets/images/solution-bg1.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	color: #fff;
	margin: 0 auto;
	.w {
		width: 1300px;
		margin: 0 auto;
		border: 1px solid transparent;
		font-family: "DFPLiJinHeiW8-GB";
		.p1 {
			width: 650px;
			font-size: 30px;
			color: #fff;
			margin-top: 206px;
		}
		.p2 {
			width: 560px;
			line-height: 30px;
			font-size: 18px;
			color: #fff;
			margin-top: 28px;
		}
		button {
			width: 120px;
			height: 40px;
			background: #297aff;
			font-size: 16px;
			color: #ffffff;
			line-height: 40px;
			text-align: center;
			border: 0;
			margin-top: 50px;
			cursor: pointer;
			transition: 0.5s all;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}
.bg2 {
	height: 607px;
	margin: 0 auto;
	.w {
		width: 1300px;
		margin: 0 auto;
		overflow: hidden;
		.bg2-title {
			width: 95%;
			margin-top: 70px;
			margin-bottom: 40px;
			padding-left: 70px;
			.p1 {
				font-size: 22px;
				font-family: "Microsoft YaHei", 微软雅黑;
				font-weight: bold;
				color: #1b1b1b;
				text-align: center;
				margin-bottom: 30px;
			}
			.hengxian {
				height: 2px;
				width: 60px;
				background-color: #1b1b1b;
				margin: 0 auto;
			}
		}
		.bg2-4box {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			height: 220px;
			.smallBox {
				width: 252px;
				height: 180px;
				font-family: "Microsoft YaHei";
				padding-top: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.bg2-4box-small-icon {
					i {
						color: #0092fb;
						font-size: 38px;
					}
				}
				.p1 {
					margin-top: 20px;
					font-size: 18px;
					font-weight: bold;
					text-align: center;
				}
				.p2 {
					font-size: 14px;
					text-align: center;
					font-size: 14px;
					line-height: 25.6px;
					width: 85%;
					margin: 20px auto 0;
				}
			}
		}
	}
}
.bg3 {
	height: 1087px;
	margin: 0 auto;
	background-color: #eff2f7;
	.w {
		width: 1300px;
		margin: 0 auto;
		overflow: hidden;
		padding-top: 10px;
		.bg3-title {
			width: 95%;
			margin-top: 50px;
			margin-bottom: 40px;
			padding-left: 70px;
			.p1 {
				font-size: 20px;
				font-family: "Microsoft YaHei", 微软雅黑;
				font-weight: bold;
				color: #1b1b1b;
				text-align: center;
				margin-bottom: 30px;
			}
			.hengxian {
				height: 2px;
				width: 60px;
				background-color: #1b1b1b;
				margin: 0 auto;
			}
		}
		.bg3-8box {
			height: 350px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.bg3-smallbox {
				width: 305px;
				height: 150px;
				background-color: #ffffff;
				padding-left: 30px;
				padding-top: 38px;
				padding-right: 30px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				transition: all 0.2s;
				&:hover {
					background: url("~@/assets/images/solution-bg3-background.png") no-repeat center;
					background-size: 100% 100%;
					.bg3-smallbox-text {
						color: #ffffff;
					}
					.bg3-smallbox-icon {
						i {
							color: #fff;
						}
					}
				}
				.bg3-smallbox-icon {
					margin-right: 20px;
					i {
						font-size: 39px;
						width: 100%;
						height: 100%;
						color: #0092fb;
					}
				}
				.bg3-smallbox-text {
					font-family: "Microsoft YaHei";
					flex: 1;
					.p1 {
						font-size: 18px;
						font-weight: bold;
						margin-bottom: 10px;
					}
					.p2 {
						font-size: 14px;
						line-height: 25.6px;
					}
				}
			}
		}
		.bg3-2box {
			display: flex;
			justify-content: space-between;
			height: 366px;
			.bg3-2box-left,
			.bg3-2box-right {
				width: 622px;
				height: 366px;

				border-radius: 15px;
			}
			.bg3-2box-left {
				background: url("~@/assets/images/solution-bg3-2box-left.png") no-repeat center;
				background-size: 100% 100%;
			}
			.bg3-2box-right {
				background-color: #fff;
				padding-top: 40px;
				padding-left: 65px;
				padding-right: 40px;
				.bg3-2box-right-4smallBox {
					height: 75px;
					margin-bottom: 26px;
					.bg3-2box-right-4smallBox-icon {
						height: 32px;
						margin-bottom: 15px;
						display: flex;
						.icon1,
						.icon2 {
							width: 30px;
							height: 30px;
							margin-right: 15px;
							i {
								font-size: 30px;
								color: #0092fb;
								width: 100%;
								height: 100%;
								&.icon-solution-bg3-22 {
									font-size: 26px;
								}
							}
						}
						.p1 {
							font-size: 16px;
							font-family: "Microsoft YaHei";
							font-weight: bold;
							color: #1b1b1b;
							line-height: 32px;
							margin-right: 15px;
							width: 75px;
						}
						.p2 {
							font-size: 16px;
							font-family: "Microsoft YaHei";
							font-weight: bold;
							color: #1b1b1b;
							line-height: 32px;
							margin-right: 15px;
						}
						.jiaicon {
							width: 30px;
							height: 30px;
							line-height: 26px;
							font-size: 35px;
							overflow: hidden;
							color: #627292;
							margin-right: 20px;
						}
					}
					.p3 {
						margin-top: 0px;
						font-size: 14px;
						font-family: "Microsoft YaHei";
						color: #1b1b1b;
						line-height: 25.6px;
					}
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.bg1 {
		background-image: url("~@/assets/images/solution-bg1-mobile.png");
		display: flex;
		align-items: flex-end;
		height: 440px;
		.w {
			width: 92%;
			margin-bottom: 15px;
			.p1,
			.p2 {
				width: 100%;
				color: rgba(255, 255, 255, 0.8);
				font-size: 20px;
				text-align: center;
				margin-top: 0;
			}
			.p2 {
				font-size: 14px;
				margin: 10px 0;
				line-height: 24px;
			}
		}
	}
	.bg2 {
		height: auto;
		.w {
			width: 92%;
			.bg2-title {
				width: 100%;
				margin: 20px 0;
				padding: 0;
				.p1 {
					font-size: 20px;
					margin-bottom: 15px;
				}
				.hengxian {
					height: 1px;
				}
			}
			.bg2-4box {
				height: auto;
				.smallBox {
					width: 48%;
					height: auto;
					margin-bottom: 10px;
					.p1 {
						margin-top: 10px;
						font-size: 14px;
					}
					.p2 {
						margin-top: 10px;
						font-size: 12px;
					}
					.bg2-4box-small-icon {
						i {
							font-size: 25px;
						}
					}
				}
			}
		}
	}

	.bg3 {
		height: auto;
		padding-bottom: 20px;
		.w {
			width: 92%;
			.bg3-title {
				margin: 10px 0 15px;
				padding: 0;
				width: 100%;
				.p1 {
					margin-bottom: 15px;
				}
			}
			.bg3-8box {
				height: auto;

				.bg3-smallbox {
					width: 48%;
					height: auto;
					padding: 10px;
					margin-bottom: 15px;
					border-radius: 5px 5px 5px 5px;

					.bg3-smallbox-icon {
						margin-right: 10px;
						i {
							font-size: 24px;
						}
					}
					.bg3-smallbox-text {
						.p1 {
							font-size: 14px;
						}
						.p2 {
							font-size: 12px;
							line-height: 20px;
						}
					}
				}
			}

			.bg3-2box {
				height: auto;
				flex-direction: column;
				.bg3-2box-left {
					width: 100%;
					height: 195px;
				}
				.bg3-2box-right {
					padding: 0;
					width: 100%;
					height: auto;
					border-radius: 0;
					background-color: transparent;
					.bg3-2box-right-4smallBox {
						padding: 10px;
						height: auto;
						border-radius: 5px;
						margin: 10px 0 0;
						background-color: #ffffff;
						.bg3-2box-right-4smallBox-icon {
							align-items: center;
							.icon1,
							.icon2 {
								width: 20px;
								height: 20px;
								margin-right: 10px;
								i {
									font-size: 20px;
									&.icon-solution-bg3-22 {
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
